import {
    Component,
    OnInit,
    OnDestroy
  } from '@angular/core';
  
  import { Store } from '@ngrx/store';
  import { Actions } from '@ngrx/effects';
  import { Observable, Subject, BehaviorSubject } from 'rxjs';
  
  import * as fromQuoteStore from '@app/quotes/store';
  import * as fromCoreStore from '@app/core/store';
  
  import {
    Order,
    OrderVehicle
  } from '@app/shared/models';
import { ActivatedRoute } from '@angular/router'
  
  @Component({
    selector: 'app-candidate-history-router',
    template: `
    <div class="container mt-3 mb-3">
      <div class="row">
        <div class="w-100">
          <app-candidate-item-history [candidate]="candidate$ | async"></app-candidate-item-history>
        </div>
      </div>
    </div>
    `
  })
  export class CandidateHistoryRouterComponent implements OnInit, OnDestroy {
  
    candidate$
    destroyed$ = new Subject<boolean>();
  
    historyResources$ = new BehaviorSubject<any>([]);
  
    constructor(
      private route: ActivatedRoute,
      private store: Store<fromQuoteStore.State>
    ) {}
  
    ngOnInit() {
      const url = this.route.snapshot.url
      this.store.dispatch(new fromCoreStore.GetCandidate({ id: url[1].path}))
      this.candidate$ = this.store.select(fromCoreStore.selectCurrentCandidate);
    }
  
    ngOnDestroy() {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  
  }
  