<div class="bg-white font-poppins rounded-lg candidate-form-wrapper">
  <div class="flex justify-between candidate-form-header items-center">
    <h4 class="text-gray-900 form-title font-medium">Candidate Form</h4>
    <span aria-hidden="true" class="cursor-pointer" (click)="close()">
      <i class="fas fa-close text-gray-400 text-lg"></i>
    </span>
  </div>
  <div class="candidate-form-main">
    <form [formGroup]="candidateForm">
      <div class="bg-white form-item">
        <label class="form-label">Your name(Required)</label>
        <app-input [idInfo]="'name'" [placeholder]="'Name'" (onSave)="candidateForm.get('name').setValue($event)" [type]="'text'"></app-input>
        <span class="invalid-span" *ngIf="candidateForm.get('name').invalid &&
        (candidateForm.get('name').dirty || candidateForm.get('name').touched)">Please input name!</span>
      </div>
      <div class="bg-white form-item">
        <label class="form-label">Email Address(Required)</label>
        <app-input [idInfo]="'email'" [options]="{padding_left_input: false}"  [placeholder]="'Email'" (onSave)="candidateForm.get('email').setValue($event)" [type]="'email'"></app-input>
        <div class="invalid-span" *ngIf="candidateForm.get('email').invalid &&
        (candidateForm.get('email').dirty || candidateForm.get('email').touched)">Please input email!</div>
      </div>
      <div class="bg-white form-item">
        <label class="form-label">Phone Number(Required)</label>
        <app-input [idInfo]="'phone'" [options]="{padding_left_input: false}" [placeholder]="'Phone'" (onSave)="candidateForm.get('phone').setValue($event)" [type]="'phone'"></app-input>
        <div *ngIf="
          candidateForm.get('phone').invalid &&
          (candidateForm.get('phone').dirty || candidateForm.get('phone').touched)
          " class="invalid-span">
            Please input phone!
        </div>
      </div>
      <div class="bg-white form-item">
        <label class="form-label">Do you have a CDL Class-A?</label>
        <app-select
          [idInfo]="'CDL'"
          (onSave)="setClass($event)"
          [value]="candidateForm.get('cdl').value || ''"
          [list]="cdl"
          [keyName]="{key:'_id', name:'value'}"
          [options]="{noFirstOption: true}"
        ></app-select>
        <div class="invalid-span" *ngIf="candidateForm.get('cdl').invalid &&  (candidateForm.get('cdl').dirty || candidateForm.get('cdl').touched)"
        >Please input cdl!</div>
      </div>
      <div class="bg-white form-item" *ngIf="candidateForm.get('cdl').value ==='yes'">
        <label class="form-label">How much CDL-A driving experience do you have?</label>
        <app-select
          [idInfo]="'CDL-A'"
          (onSave)="setDriving($event)"
          [value]="candidateForm.get('cdlExp').value || ''"
          [list]="cdlA"
          [keyName]="{key:'_id', name:'value'}"
          [options]="{noFirstOption: true}"
        ></app-select>
        <div class="invalid-span" *ngIf="candidateForm.get('cdlExp').invalid &&  (candidateForm.get('cdlExp').dirty || candidateForm.get('cdlExp').touched)"
        >Please input cdlExp!</div>
      </div>
      <div class="bg-white form-item" *ngIf="candidateForm.get('cdlExp').value&&candidateForm.get('cdl').value ==='yes'">
        <label class="form-label">How much auto hauling experience do you have?</label>
        <app-select
          [idInfo]="'hauling'"
          (onSave)="setHaulingExp($event)"
          [value]="candidateForm.get('autoHaulingExp').value || ''"
          [list]="autoHaulingExp"
          [keyName]="{key:'_id', name:'value'}"
          [options]="{noFirstOption: true}"
        ></app-select>
        <div class="invalid-span" *ngIf="candidateForm.get('autoHaulingExp').invalid &&  (candidateForm.get('autoHaulingExp').dirty || candidateForm.get('autoHaulingExp').touched)"
        >Please input autoHaulingExp!</div>
      </div>
      <div class="bg-white form-item">
        <label class="form-label">Additional Information</label>
        <textarea 
          formControlName="additionalInformation"
          id="additional" 
          rows="4" 
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
          placeholder="Write text here ...">{{candidateForm.value.additionalInformation.question}}</textarea>
      </div>
      <button class="add-candidate-btn w-full text-white font-medium" (click)="save()">
        Add Candidate
      </button>
    </form>
  </div>
</div>

