import { ActivatedRoute } from '@angular/router'
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, combineLatest, Subject } from 'rxjs'
import { tap, distinctUntilChanged, withLatestFrom, takeUntil, map, filter } from 'rxjs/operators'

import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'


import { User } from '@app/shared/models'
import { SocketService, Candidate, getContentHTMLWithMention } from '@app/shared'
import * as fromSidebarStore from '@app/sidebar/store'

@Component({
    selector: 'app-candidates',
    template: `
    <div class="candidates-list__container">
      <div class="candidates-list__wr">
        <app-candidate-list
          [candidates]="candidates$ | async"
          [currentUser]="currentUser$ | async"
        ></app-candidate-list>
      </div>
    </div>
  `,

    styles: [
        `
      .candidates-list__container {
        background-color: white;
        min-height: 100vh;
      }
      .candidates-list__wr {
        width: 99%;
        margin-left: auto;
        margin-right: auto;
      }
    `
    ]
})
export class CandidatesComponent implements OnInit, OnDestroy {
    candidates$: Observable<Candidate[]>

    users$: Observable<User[]>

    currentUser$: Observable<User>
    loggedIn$: Observable<any>

    destroyed$ = new Subject<boolean>()

    filter$: Observable<any>

    filter

    selectedStatusKey = 'candidate-list'
    statusPopoverKey = 'popover'

    isLoggedIn

    constructor(
        private store: Store<fromCoreStore.State>,
        public _SocketService: SocketService,
        private route: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
    ) { }

    ngOnInit() {

      this.filter$ = this.store.select(fromCoreStore.selectCandidateFilter)
      .pipe(tap(f => {
          this.filter = f
          this.store.dispatch(new fromCoreStore.GetCandidates(f))
      }))
      
      // this.filter$ = this.store.select(fromCoreStore.selectCandidateFilter)
      // .pipe(tap(f => {
      //     console.log( f )
      //     return this.filter = f
      // }))

      this.candidates$ = this.store.select(fromCoreStore.selectAllCandidates)

      this.store.select(fromUserStore.getLoggedIn).pipe(
            takeUntil(this.destroyed$),
            tap(loggedIn => {
                this.isLoggedIn = loggedIn
            })
        ).subscribe()
      this.currentUser$ = this.store.select(fromUserStore.getUser)
        
    }

    ngAfterContentChecked(): void {
      this.changeDetector.detectChanges();
    }

    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}