<div class="candidate-details font-poppins relative">
    <div class="candidate-container-top">
        <div class="candidate-item container-candidate-left" #containerCandidateLeft>
            <div class="general-information">
                <div class="general-information-wr">
                    <img class="rounded-full candidate-avatar-img" src="../../../assets/avatar-default.png" alt="candidate-avatar"/>
                    <div class="info-name-wr">
                        <div class="info-name-wr-adaptive relative">
                            <app-input 
                                class="edit-name"
                                *ngIf="edit"
                                [label]="'Name'"
                                [idInfo]="'cand-name'"
                                [value]="candidate?.contact?.name"
                                [type]="'text'"
                                (onSave)="updateCandidateContact({'name': $event})"
                                [options]="{adaptive: true}"
                            >
                            </app-input>
                            <p *ngIf="!edit" class="info-name">{{candidate?.contact?.name}}</p>
                            <p class="info-date" [ngClass]="{'edit_date': edit}">{{candidate.createdAt | date : 'short'}}</p>
                        </div>
                        <div class="job" [ngClass]="{'edit_job': edit}">
                            <span class="job-name">{{candidate.job.name}}</span>
                            <div class="rating-wrapper">
                                <span class="rating-title">Rating:</span>
                                <app-rating [rating]="candidate.contact.rating" (setRating)="setRating($event)"></app-rating>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="candidate-actions">
                    <button routerLink="/recruiter/candidate/{{ candidate?._id }}/history" title="Show History">
                        <i class="fa-solid historyInfoBtn fa-clock-rotate-left text-gray-500"></i>
                    </button>
                    <button [popper]="editInfoBtn" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.BOTTOM" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                        <i class="fa-solid editInfoBtn fa-ellipsis text-gray-500"></i>
                    </button>
                    <popper-content #editInfoBtn class="z-50">
                        <div class="text-black rounded-md bg-white editInfoBox">
                          <div class="bg-white rounded-md">
                            <button (click)="edit = !edit; closePopper()" class="editInfoButton text-gray-700 w-full text-left">Edit Info</button>
                            <button (click)="onDelete(candidate); closePopper()" class="editInfoButton text-red-500 w-full text-left"><i class="fa-solid fa-trash"></i>Delete Candidate</button>
                          </div>
                        </div>
                      </popper-content>
                </div>
            </div>
            <div class="candidate-info">
                <div class="candidate-info-item">
                    <p class="candidate-info-title font-medium text-gray-900">Status</p>
                    <div class="select-stage-wr">
                        <app-select
                        (onSave)="setStage($event)"
                        [value]="candidate?.stage || ''"
                        [list]="stageTypes"
                        [keyName]="{key:'key', name:'name'}"
                        [options]="{withoutLabel: true, adaptive: true}"
                    ></app-select>
                    </div>
                </div>
                <div class="candidate-info-item candidate-info-right">
                    <p class="candidate-info-title font-medium text-gray-900">Haul Type</p>
                    <div class="haul-types">
                        <button
                          id="is-enclosed-open"
                          type="button"
                          class="haul-type text-gray-900"
                          [ngClass]="candidate.haulType === 'LOCAL' ? 'haul-type-avtive' : null"
                          (click)="patchCandidate({ haulType: 'LOCAL' })"
                        >
                          Local
                        </button>
                        <button
                          id="is-enclosed-enclosed"
                          type="button"
                          class="haul-type wide text-gray-900"
                          [ngClass]="candidate.haulType === 'LONGHAUL' ? 'haul-type-avtive' : null"
                          (click)="patchCandidate({  haulType: 'LONGHAUL'  })"
                        >
                            Long Haul
                        </button>
                        <button
                          id="is-enclosed-expedited"
                          type="button"
                          class="haul-type text-gray-900"
                          [ngClass]="candidate.haulType === 'BOTH' ? 'haul-type-avtive' : null"
                          (click)="patchCandidate({ haulType: 'BOTH' })"
                        >
                          Both
                        </button>
                    </div>
                </div>
            </div>
            <div class="candidate-info-footer">
                <div class="small-tablet">
                    <app-contact-details 
                        style="width: 100%;"
                        [contact]="candidate.contact"
                        [isReadOnly]="!edit"  
                        (onNotify)="updateCandidateContactNotify($event)"
                        (onUpdate)="updateCandidateContact($event)"
                        [fields]="{
                            phones: { action: 'crud' },
                            emails: { action: 'crud' },
                            address: { action: 'crud' },
                            contact: true,
                        }"
                        [options]="{
                            create:true,
                            filter:true, 
                            inputsOpatity: true,
                            noName: true, 
                            inputDescription: true, 
                            bgGray: true, 
                            editAddress: edit, 
                            canRemove: edit,
                            adaptive: true, 
                            noSelectToMerge: true,
                            no_user_icon: true
                        }"       
                    >
                    </app-contact-details>
                </div>
                <div  class="large-tablet flex items-center">
                    <app-contact-details 
                        style="width: 100%;"
                        [contact]="candidate.contact"
                        [isReadOnly]="!edit"  
                        (onNotify)="updateCandidateContactNotify($event)"
                        (onUpdate)="updateCandidateContact($event)"
                        [fields]="{
                            phones: { action: 'crud' },
                            emails: { action: 'crud' },
                            address: { action: 'crud' },
                            contact: true,
                        }"
                        [options]="{
                            create:true,
                            filter:true, 
                            containerFlex: true, 
                            inputsOpatity: true,
                            noName: true, 
                            inputDescription: true, 
                            bgGray: true, 
                            editAddress: edit, 
                            canRemove: edit,
                            adaptive: true, 
                            noSelectToMerge: true,
                            no_user_icon: true
                        }"       
                    >
                    </app-contact-details>
                </div>
            </div>
        </div> 

        <div class="candidate-item container-candidate-right container-candidate-right-top" 
        [ngStyle]="{'top': containerCandidateLeft.offsetWidth === 274 ? tasksContainer.offsetHeight+10+'px' : 'auto'}">
            <p class="candidate-item-title">General Questions</p>
            <div *ngIf="generalQuestions.length" class="questions-items">
                @for(q of generalQuestions; track $index){
                    <div class="question bg-gray-100">
                        <div class="question-title font-medium text-gray-500">{{q.question}}</div>
                        <div class="question-text">
                            <textarea 
                                (change)="addQuestion($event,q._id)" 
                                [value]="q.answer"
                                autogrow
                                id="message" 
                                class="block textarea-default p-2.5 w-full text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                placeholder="Type reply here"
                            ></textarea>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    <div class="candidate-container-bottom">
        <div #candidateItem class="candidate-bottom-left container-candidate-left">
            <div class="bottom-lef-item bottom-lef-item-files">
                <div class="files-item">
                    <app-files
                        [resource]="FOLLOW_UP_RESOURCE.CANDIDATE"
                        [resourceId]="candidate?.parentId ? [candidate?._id, candidate?.parentId] : candidate?._id"
                    ></app-files>
                </div>
            </div>
            <div #tasksContainer class="bottom-lef-item bottom-lef-item-notes" [ngStyle]="{'min-height': containerCandidateLeft.offsetWidth === 274 ? containerCandidateLeft.offsetHeight+'px' : 'auto'}">
                <div class="tasks-buttons">
                    <button class="text-gray-500 font-medium" [ngClass]="{'linkNotes-active': linkNotes === 'notes'}" (click)="linkNotes = 'notes'">Notes</button>
                    <button class="text-gray-500 font-medium" [ngClass]="{'linkNotes-active': linkNotes === 'tasks'}" (click)="linkNotes = 'tasks'">Tasks</button>
                </div>
                <hr class="bg-gray-500">
                <div class="bottom-lef-container">
                    <app-notes 
                        *ngIf="linkNotes === 'notes'"
                        class="doNotLock"
                        id='customer-notes'
                        [options]="{ list: true }"
                        [display]="{
                            tabs: { notes: true },
                            select: { assignee: true, visibility: true, date: true, typeTask: true, complete: true }
                        }"
                        [creator]="currentUser"
                        [usersEntities]="users"
                        [filter]="true"
                        [idInfo]="'customer-notes'"
                        [resource]="'CANDIDATE'"
                        [resourceId]="candidate?._id"
                        >
                    </app-notes>
                    <app-tasks-container  
                        *ngIf="linkNotes === 'tasks'"
                        id='candidate-tasks'
                        [currentUser]="currentUser"
                        [resource]="{
                            resource: FOLLOW_UP_RESOURCE.CANDIDATE,
                            resourceId: candidate?._id,
                            resourceName: candidate?.contact?.name
                        }"
                    ></app-tasks-container>
                </div>
            </div>
        </div>

        
        <div class="container-candidate-right container-candidate-right-bottom" [ngStyle]="{'top': containerCandidateLeft.offsetWidth === 274 ? tasksContainer.offsetHeight+410+'px' : 'auto'}">
            <div class="candidate-item">
                <p class="candidate-item-title">Additional Questions</p>
                <div class="additional-questions">
                    <div class="additional-questions-wr">
                        <div class="question-additional bg-gray-100">
                            <div class="question-title font-medium text-gray-500">Date of Birthday</div>
                            <div class="question-text"><app-date [options]="{'bgWhite': true}" [startKey]="'dob'" [parent]="candidate?.contact" (onSave)="updateDate($event)"></app-date> </div>
                        </div>
                        @for(q of additionalQuestions; track q._id){
                            <div class="question-additional bg-gray-100">
                                <div class="question-title font-medium text-gray-500">{{q.question}}</div>
                                <div class="question-text">
                                    <textarea 
                                        (change)="addQuestion($event,q._id)" 
                                        [value]="q.answer"
                                        autogrow
                                        id="message" 
                                        class="block textarea-default p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                        placeholder="Type reply here"
                                    ></textarea>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div *ngIf="containerCandidateLeft.offsetWidth === 274" class="h-5"></div>
        </div>
    </div>

</div>

