<div class="bg-white" style="min-height: 100vh;">
  <div class="candidate-list-wrapper w-full font-poppins bg-white">
    <div class="candidate-list-header">
      <div class="candidate-filters">
        <app-filters
          [status]="stageTypes"
          [property]="'stage'"
          [options]="{ upperCase: true }"
          [filters]="filter$ | async"
          (filterSet)="setFilter($event)"
        ></app-filters>
      </div>
      <div *appHasPermission="'recruiter_create'" class="recruiter_create-wr-btn">
        <a
          class="recruiter_create_btn text-white font-medium rounded-lg cursor-pointer"
          data-toggle="modal"
          data-target="#candidate_modal"
          (click)="openForm()"
        >
          ADD CANDIDATE
        </a>
      </div>
    </div>
    <div>
      <table class="custom-table w-full">
        <thead>
          <tr>
            <th><span>NAME</span><button (click)="setFilter({'name': filterBy?.name == 'increase' ? 'decrease' : 'increase'})"><i [ngClass]="[filterBy?.name == 'increase' ? 'fa-chevron-down' : 'fa-chevron-up']" class="fa-solid ml-2 text-xs" style="color: #bdbdbd;"></i></button></th>
            <th><span>JOB</span><button (click)="setFilter({'job': filterBy?.job == 'increase' ? 'decrease' : 'increase'})"><i [ngClass]="[filterBy?.job == 'increase' ? 'fa-chevron-down' : 'fa-chevron-up']" class="fa-solid ml-2 text-xs" style="color: #bdbdbd;"></i></button></th>
            <th><span>STAGE</span><button (click)="setFilter({'stageType': filterBy?.stageType == 'increase' ? 'decrease' : 'increase'})"><i [ngClass]="[filterBy?.stageType == 'increase' ? 'fa-chevron-down' : 'fa-chevron-up']" class="fa-solid ml-2 text-xs" style="color: #bdbdbd;"></i></button></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for(row of candidates; track row._id){
            <tr>
              <td>
                <a class="recruiter-link" [routerLink]="['/recruiter/candidate', row._id]" *ngIf="!row?.deleted" routerLinkActive="router-link-active">
                  {{ row.contact.name }}
                </a>
              </td>
              <td>
                <div class="flex items-center">
                  <span class="job-type">{{ row.job.name }}</span>
                  <span class="job-type ml-2" *ngIf="row?.haulType">{{ row.haulType }}</span>
                  <span class="candidate-rating flex">
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 0}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 1}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 2}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 3}" class="fa-solid fa-star"></i>
                    <i [ngClass]="{'yelow-star': row?.contact?.rating > 4}" class="fa-solid fa-star"></i>
                  </span>
                </div>
              </td>
              <td>{{ row.stage }}</td>
              <td>
                <a class="view recruiter-link" [routerLink]="['/recruiter/candidate', row._id]" *ngIf="!row?.deleted" routerLinkActive="router-link-active">
                  View
                </a>
              </td>
            </tr>
          }
        </tbody>
      </table>
      <div class="w-full mt-3">
        <app-pagination class="pag-wrapper" *ngIf="candidates?.length" (pageChange)="onPageChange($event)" [total]="total" [limit]="filter?.limit" [currentPage]="filter?.page"></app-pagination>
      </div>
  </div>
</div>
