<div class="tab-content">
    <div class="tab-pane active m-scrollable" id="m_quick_sidebar_tabs_messenger" role="tabpanel">
      <div  class="m-content">
        <div *ngIf="_candidate?._id" class="container-fixed">
          <div class="m-portlet__body m-portlet__body--no-padding">
            <a routerLink="/recruiter/candidate/{{ _candidate?._id }}" class="btn btn-outline-secondary back-btn mb-3"
              ><i class="fas fa-arrow-left"></i>&nbsp;<span>Back</span>
            </a>
            <app-history-logs
              [resourceType]="'candidates'" [resourceId]="_candidate?._id"
            ></app-history-logs>
          </div>
        </div>
      </div>
    </div>
  </div>
  