import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'


import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects';

import { SharedModule,  CoreComponentsModule} from '@app/shared'
import { PipesModule } from '@app/shared/pipes';
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { NgxPopperjsModule } from 'ngx-popperjs'

import { RecruiterRouterComponent } from './containers/recruiter-router.component'
import { CandidatesComponent } from './containers/candidates.component'
import { CandidateListComponent } from './components/candidate-list.component'
import { CandidateItemComponent } from './containers/candidate-item.component'
import { CandidateDetailsComponent } from './components/candidate-detalis.component'
import { CandidateItemRouterComponent } from './containers/candidate-item-router.component'
import { CandidateFormComponent } from './components/candidate-form.component'
import { CandidateHistoryRouterComponent } from './components/candidate-history/candidate-history-router.component'
import { CandidateItemHistoryComponent } from './components/candidate-history/candidate-item-history.component'

const RecruiterRouting = RouterModule.forChild([
  {
    path: '',
    component: RecruiterRouterComponent ,
  },
  {
    path: 'candidate/:candidateId',
    component: CandidateItemRouterComponent,
    children:[
      {
        path: '',
        component: CandidateItemComponent,
      },
    ]
  },
  {
    path: 'candidate/:candidateId/history',
    component: CandidateHistoryRouterComponent,
  },
]);

@NgModule({
  declarations: [
    RecruiterRouterComponent,
    CandidatesComponent,
    CandidateListComponent,
    CandidateItemComponent,
    CandidateDetailsComponent,
    CandidateItemRouterComponent,
    CandidateFormComponent,
    CandidateHistoryRouterComponent,
    CandidateItemHistoryComponent
  ],
  imports: [
    CommonModule,
    EffectsModule,
    StoreModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CoreComponentsModule,
    RecruiterRouting,
    NgxDatatableModule,
    PipesModule,
    NgxPopperjsModule
  ],
  exports: [
    CandidateFormComponent
  ]
})
export class RecruiterModule { }
