import { Component, OnInit, Input, OnDestroy, } from '@angular/core'

import * as fromCoreStore from 'app/core/store'
import { OpenModal } from '@app/modals/store/actions/layout.actions'
// import { OpenModal } from '@app/modals/store/actions/layout.actions' //E:\CRM_V2\src\app\core\store\selectors\candidate-filter.selectors.ts

import { Candidate, User } from '@app/shared'
import { Observable, Subject } from 'rxjs'
import { Store } from '@ngrx/store'
import { ToastHelper } from '@app/core/services'
import { Actions } from '@ngrx/effects'
import { map, tap, takeUntil } from 'rxjs/operators'
import { popupService } from '@app/shared/services/popup.service'
import { ActivatedRoute } from '@angular/router'
// import * as fromShopStore from '@app/core/store/selectors/candidate-filter.selectors'


@Component({
    selector: 'app-candidate-list',
    templateUrl: './candidate-list.component.html',
    styleUrls: ['./candidate-list.component.css'],
})
export class CandidateListComponent implements OnInit, OnDestroy{

    @Input()
    candidates = []
    // candidatesCounts$: Observable<any>
    // countsCandidates$: Observable<any[]>
    pagesCount
    @Input()
    currentUser: User
    filter$: Observable<any>
    filter: any
    destroyed$ = new Subject<boolean>()
    filterBy
    total
    stageTypes = [
        { name: 'All', key:'All', count: 0},
        { name: 'New', key: 'New', count: 0 },
        { name: 'Active', key: 'Active', count: 0 },
        { name: 'On-Hold', key: 'On-Hold', count: 0 },
        { name: 'Reviewed', key: 'Reviewed', count: 0 },
        { name: 'Contacting', key: 'Contacting', count: 0 },
        { name: 'Hired', key: 'Hired', count: 0},
        { name: 'Rejected', key: 'Rejected', count: 0 }
    ]

    constructor(
        private store: Store<fromCoreStore.State>,
        public notify: ToastHelper,
        private popupService: popupService,
        private route: ActivatedRoute,
    ){}
    
    statuses = ['Candidates']
      
    ngOnInit() {

        this.filter$ = this.store.select(fromCoreStore.selectCandidateFilter)
        .pipe(tap(f => {
            this.route.queryParams.subscribe(params => {
              if (params?.page) {
                let page = Number(params.page)
                f.page = page
              } else {
                f.page = 1
              }
            });
            this.filter = f
            this.filterBy = JSON.parse(f.filterBy)
            this.store.dispatch(new fromCoreStore.GetCandidates(f))
        }))

        this.store.select(fromCoreStore.selectCandidateCountsState).pipe(takeUntil(this.destroyed$)).subscribe(counts => {

            for (let item of this.stageTypes) {
                item.count = 0
            }
            for (let type of this.stageTypes) {
                for (let item of counts) {
                    type.name == item._id ? type.count = item.count : null
                    if (this.filter?.stage == null) {
                        type.name == 'All' ? this.total = type.count : null
                    } else {
                        type.name == this.filter.stage ? this.total = type.count : null
                    }
                }
            }
        })
    }

    onPageChange(event) {
        this.filter.page = event
        this.store.dispatch(new fromCoreStore.GetCandidates(this.filter))
      }
    
    setFilter = filter => {
        if (filter?.name) {
            this.filterBy.name = filter.name
            filter.filterBy = JSON.stringify(this.filterBy)
        }

        if (filter?.stageType) {
            this.filterBy.stageType = filter.stageType
            this.filterBy.name = ''
            filter.filterBy = JSON.stringify(this.filterBy)
        }

        if (filter?.job) {
            // return
            this.filterBy.job = filter.job
            this.filterBy.name = ''
            filter.filterBy = JSON.stringify(this.filterBy)
        }

        for (let stage of this.stageTypes) {
            filter.stage == stage.name ? this.total = stage.count : null
        }

        this.store.dispatch(new fromCoreStore.SetCandidatesFilter({ ...this.filter, ...filter }))
    }

    openForm = () => this.store.dispatch(new OpenModal({
        type: 'CANDIDATE_FORM',
        props: {
          windowClass : "candidate-form-modal"
        },

    }))
    onDelete = async (data) => {
        if (await this.popupService.confirm(`Are you sure you want to delete ${data.contact.name}?`)) {
            this.store.dispatch(new fromCoreStore.DeleteCandidate({ id: data._id, item: data.creator._id}))
        }
    }
    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}



