import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core'

import { Subject } from 'rxjs'

import { Order, OrderVehicle } from '@app/shared/models'

@Component({
  selector: 'app-candidate-item-history',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './candidate-item-history.component.html'
})
export class CandidateItemHistoryComponent implements OnInit, OnDestroy {


  destroyed$ = new Subject<boolean>()
  _candidate
  @Input()
  set candidate(val) {
    if(val){
        this._candidate = val
    }
  }


  constructor() { }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
